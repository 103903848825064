<template>
  <div class="place-items-start">
    <div v-if="!isStarted || !seconds" class="place-items-start">
      <p-button
        class="otp-counter__button"
        size="sm"
        button-type="ghost"
        append-icon="arrow-right-large"
        @click="onCodeAgain"
      >
        {{ isStarted ? $t('otp.resend') : $t('otp.send') }}
      </p-button>
    </div>

    <div v-else class="place-items-start gap-4 py-6">
      <span class="f-utility-14 t-foreground-tertiary" v-text="$t('otp.counter')" />
      <span class="f-utility-14 t-foreground-tertiary" v-text="seconds" />
    </div>
  </div>
</template>

<script lang="ts" setup>
const emit = defineEmits(['on-expire', 'on-code-again'])

const props = defineProps({
  ttl: {
    type: Number,
    default: 180,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  autoStart: {
    type: Boolean,
    default: true,
  },
})

const seconds = ref(props.ttl)
const isStarted = ref(props.autoStart)

const onCodeAgain = () => {
  emit('on-code-again')
  seconds.value = props.ttl
  isStarted.value = true
  start()
}

const start = () => {
  setTimeout(() => {
    if (seconds.value > 0) {
      seconds.value -= 1
      start()
    }
  }, 1000)

  if (seconds.value === 0) {
    emit('on-expire')
  }
}

onMounted(() => {
  if (!props.disabled && props.autoStart) {
    start()
  }
})
</script>
